import { ref, onUnmounted } from 'vue';

export function useCountdown(initialTime: number) {
  const timeRemaining = ref(0);
  const countdownActive = ref(false);
  let interval: number | null = null;

  const clear = () => {
    if (interval) {
      clearInterval(interval);
      interval = null;
    }
    countdownActive.value = false;
  };

  const startCountdown = () => {
    clear();
    timeRemaining.value = initialTime;
    countdownActive.value = true;
    interval = window.setInterval(() => {
      if (timeRemaining.value > 0) {
        timeRemaining.value -= 1;
      } else {
        clear();
      }
    }, 1000);
  };

  onUnmounted(() => {
    clear();
  });

  return {
    timeRemaining,
    countdownActive,
    startCountdown,
  };
}
